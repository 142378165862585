import styled from 'styled-components'

export const Iframe = styled.div`
  position: relative;
`
export const ExibirMensagem = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`
